<template>
  <div class="dashboard">
    <div class="dashboard__container">
      <div class="dashboard__container--body pb-5">
        <div class="dashboard__container--body--col whiteBack pt-5 mt-5 mb-5 shiftCard" style="margin: auto;">
  <!--         <div class="mb-2">
            <h2 style="border-bottom:1px solid #eee; padding-bottom:0.25rem; margin-bottom:0.5rem;">Business Inquires:</h2>
          </div> -->
          <!-- <p v-if="showSupportForm">Please submit the form below and we will get back to you right away.</p> -->
          <div v-if="!showSupportForm">
            <h3>Thank you for your request.</h3>
            <p>We will be in touch shortly.</p>
          </div>
          <form v-if="showSupportForm" @submit.prevent>
            <div class="flex justify-space-between align-center mb-2">
              <h2>S<em>u</em>pport Request</h2>
              <!-- <i class="fas fa-times fa-2x mr-1" @click="$emit('close')" style="cursor: pointer;"></i> -->
            </div>
            <div class="mb-3">
              <label for="firstname">First Name:</label>
              <input v-model.trim="form.firstname" autocomplete="given-name" type="text" placeholder="" id="firstname" required />
            </div>
            <div class="mb-3">
              <label for="lastname">Last Name:</label>
              <input v-model.trim="form.lastname" autocomplete="family-name" type="text" placeholder="" id="lastname" required />
            </div>
            <div class="mb-3">
              <label for="phone">Phone Number:</label>
              <input v-model.trim="form.phone" type="text" v-mask="'(###) ###-####'" placeholder="(###) ###-####" id="phone" required />
            </div>
            <div class="mb-3">
              <label for="email1">Email:</label>
              <input v-model.trim="form.email" autocomplete="email" type="text" placeholder="you@email.com" id="email1" minlength="7" required />
            </div>
            <div class="mb-3">
              <label for="details">How can we help? </label>
              <textarea name="details" v-model.trim="form.details" id="details" cols="30" rows="10" required></textarea>
            </div>
            <div class="pt-2">
              <button class="btn btn__primary btn__large" @click="submitForm()" :disabled="!valid">
                Submit
                <transition name="fade">
                  <span class="ml-2" v-if="performingRequest">
                  <i class="fa fa-spinner fa-spin"></i>
                  </span>
                </transition>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>


        

<script>
import { mapState } from 'vuex'

export default {
  name: 'support',
  data: () => ({
    performingRequest: false,
    showSupportForm: true,
    form: {},
  }),
  computed: {
    ...mapState(['currentUser']),
    valid() {
      if ((this.form.email && this.form.email.length > 6) && (this.form.firstname) && (this.form.lastname) && (this.form.phone) && (this.form.details)) {
        return true
      }
      else {
        return false
      }
    },
  },
  methods: {
    submitForm() {
      this.performingRequest = true
      let form = this.form
      form.userId = this.currentUser.uid
      console.log(form)
      let store = this.$store
      store.dispatch('newSupportRequest', form)
      setTimeout(() => {
        this.performingRequest = false
        this.showSupportForm = false
        form = {}
      }, 1000)
    }
  }
}
</script>